import React from 'react'

export default function MisionVisionScreen() {
    return (
        <section className="container">
            <div className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded">
                <h4 className='text-center mb-4'>Misión</h4>
                <div className="col-12 col-lg-8 offset-lg-2 mb-4">
                    <p align="justify" className="fw-lighter fst-italic">
                        Somos la unidad encargada de seleccionar, adquirir, procesar, recuperar y difundir información sociopolítica de interés para los programas de docencia e investigación de la Escuela de Ciencia Política.
                    </p>
                </div>
                <h4 className='text-center mb-4'>Visión</h4>
                <div className="col-12 col-lg-8 offset-lg-2">
                    <p align="justify" className="fw-lighter fst-italic">
                        Convertirnos en la unidad de información modernizada a través de servicios y productos acordes a las nuevas tecnologías de la información e incidir en la calidad académica de la Escuela de Ciencia Política.
                    </p>
                </div>
            </div>
        </section>
    )
}
