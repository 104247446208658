import React from 'react'

export default function WebsitesScreen() {
    return (
        <section className="container">
            <div className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded">
                <h4 className='text-center mb-4'>Enlaces</h4>
                <div className="col-12 col-lg-8 offset-lg-2 mb-4">
                    <ul>
                        <li>
                            <div align="justify" className="fw-bold">ASIES</div>
                        </li>
                        <li>
                            <div align="justify"><span >Investigación, análisis e incidencia</span><br />
                                <a href="http://www.asies.org.gt/">http://www.asies.org.gt/</a></div>
                        </li>
                    </ul>
                    {/* <ul>
                        <li>
                            <div align="justify" className="fw-bold"><span className="fw-bold">FLACSO</span></div>
                        </li>
                        <li>
                            <div align="justify"><span >Facultad Latinoamericana de Ciencias Sociales<br />
                                <a href="http://biblioteca.flacso.edu.gt/library/index.php?title=Especial:GSMSearchPage">http://biblioteca.flacso.edu.gt/library/index.php?title=Especial:GSMSearchPage</a></span></div>
                        </li>
                    </ul> */}
                    <ul>
                        <li>
                            <div align="justify" className="fw-bold">AVANCSO</div>
                        </li>
                        <li>
                            <div align="justify"><span >Avance de las ciencias sociales en Guatemala</span><br />
                                <a href="http://www.avancso.org.gt/">http://www.avancso.org.gt/</a></div>
                            <div align="justify"></div>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <div align="justify"><span className="fw-bold">INTAL</span></div>
                        </li>
                        <li>
                            <div align="justify"><span>Instituto   para la Integración de América Latina y el Caribe</span><strong><br />
                            </strong><a href="https://www.iadb.org/es/quienes-somos/tematicas/comercio/publicaciones">https://www.iadb.org/es/quienes-somos/tematicas/comercio/publicaciones</a></div>
                        </li>
                    </ul>
                </div>
            </div>
        </section >
    )
}
