import React from 'react'

export default function CollectionsScreen() {
    return (
        <section className="container">
            <div className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded">
                <h4 className='text-center mb-4'>Colecciones</h4>
                <div className="col-12 col-lg-8 offset-lg-2 mb-4">
                    <p align="justify" className="fw-lighter fst-italic">
                        El  Centro de Documentación cuenta con una amplia variedad de bibliografía actual e  histórica, disponible tanto para la comunidad Universitaria de la Escuela de  Ciencia Política (estudiantes de grado, postgrado, profesores, investigadores y  personal administrativo) así como para los egresados,  estudiantes de otras Universidades y público  en general.
                    </p>
                    <p align="justify" className="fw-lighter fst-italic"><strong><br />
                        <span className="fw-bold fst-italic">Colección General:</span></strong></p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        Contiene  libros de textos para los cursos de Ciencia Política,  Sociología, Relaciones Internacionales y áreas afines. </p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        <span className="fw-bold fst-italic">Colección de Diseños de Tesis:</span></p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        <strong className="fw-lighter fst-italic">Contiene temas aprobados para  la elaboración de tesis de las tres carreras</strong>.</p>
                    <p align="justify" className="fw-lighter fst-italic"><span className="fw-bold fst-italic"><br />
                        <strong>Colección de Tesis:</strong></span></p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        Conformada por las Tesis de los egresados de la Escuela de Ciencia  Política.</p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        <span className="fw-bold fst-italic">Colección  de Referencia:</span></p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        Formada  por atlas, enciclopedias y diccionarios de Relaciones Internacionales, Ciencia  Política y Sociología.</p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        <span className="fw-bold fst-italic">Colección  de Documentos: </span></p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        Contiene  documentos de diversas áreas temáticas. </p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        <span className="fw-bold fst-italic">Colección  de Revistas:</span> </p>
                    <ul>
                        <li className="fw-lighter fst-italic">
                            <div align="justify">Conformada por revistas nacionales y extranjeras.</div>
                        </li>
                        <li className="fw-lighter fst-italic">
                            <div align="justify">Publicaciones periódicas del Instituto de Investigaciones de la Escuela de Ciencia Política.</div>
                        </li>
                    </ul>
                    <p align="justify" className="fw-bold fst-italic">Colección de CDS:</p>
                    <p align="justify" className="fw-lighter fst-italic">Con información de diversas áreas temáticas. </p>
                </div>
            </div>
        </section>
    )
}
