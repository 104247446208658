import React from 'react'

export default function HistoryScreen() {
    return (
        <section className="container">
            <div className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded">
                <h4 className='text-center mb-4'>Historia</h4>
                <div className="col-12 col-lg-8 offset-lg-2">
                    <p align="justify" className="fw-lighter fst-italic">El Centro de Documentación de la  Escuela de Ciencia Política (CEDEC), de la Universidad de San Carlos de  Guatemala, fue creado a iniciativa del Instituto de Investigaciones Políticas y  Sociales en 1976. Es un Centro de Información especializado en las disciplinas  de Ciencia Política, Sociología y Relaciones Internacionales. </p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        Durante los primeros años de  funcionamiento se le consideró solo como un anexo del Instituto y de apoyo a  los investigadores y a los docentes de la carrera de Ciencia Política. Con la implementación  de las disciplinas de Sociología y Relaciones Internacionales, se hizo  necesario formalizarlo como un Centro de Documentación, que brindara apoyo a  las actividades académicas, adquiriendo colecciones que respondieran a los  intereses de las tres carreras y asignándole un presupuesto propio. </p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        En agosto de 1997, se inició la  automatización y sistematización de las colecciones, contando a la fecha con un  catálogo digital en el cual se encuentran las bases de datos de libros,  documentos, tesis, diseños de tesis, medios audiovisuales, revistas nacionales  y extranjeras.</p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        La compra de material se realiza  en base a la bibliografía recomendada por docentes con el objetivo de cumplir  con los objetivos de los cursos.</p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        Con la implementación de la  Maestría “Política y Sociedad” la cual inició en el año 2009, el  CEDEC&nbsp;aumentó su acervo bibliográfico con libros que responden a los  intereses de los estudiantes de la maestría.</p>
                    <p align="justify" className="fw-lighter fst-italic"> A partir de agosto del año 2009,  se inició la consulta en línea a texto completo de la Revista Política y  Sociedad que edita el Instituto de Investigaciones Políticas y Sociales y las tesis de los egresados de la Escuela de Ciencia Política, contando actualmente con la colección completa tanto de la revista como de  las tesis.</p>
                    <p align="justify" className="fw-lighter fst-italic">Además contamos con la consulta a texto completo de los diseños de tesis.</p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        Como parte de su proyección, se  han establecidos lazos de cooperación con Bibliotecas y Centros de  Documentación de la capital de Guatemala, como FLACSO, AVANCSO y ASIES.</p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        Así mismo el CEDEC es miembro  activo del Consejo Latinoamericano de Ciencias Sociales ( CLACSO Argentina ), Sistema de Bibliotecas y Centros de Documentación de la Universidad  de San Carlos de Guatemala (SIBUSAC) y de la&nbsp;Red Nacional de Documentación  Educativa de Guatemala (REDUCA).</p>
                </div>
            </div>
        </section>
    )
}
