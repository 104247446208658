import React from 'react'

export default function NormativeScreen() {
    return (
        <section className="container">
            <div className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded">
                <h4 className='text-center fw-bold'>NORMATIVO DEL</h4>
                <h4 className='text-center fw-bold'>CENTRO DE DOCUMENTACION (CEDEC)</h4>
                <h4 className='text-center fw-bold mb-4'>ESCUELA DE CIENCIA POLITICA</h4>
                <div className="col-12 col-lg-8 offset-lg-2 mb-4">
                    <h4 align="center" className="fw-bold">UNIVERSIDAD DE SAN CARLOS DE GUATEMALA</h4>
                    <p align="center" className="fw-bold"><strong>CAPITULO I</strong><br /><strong>DE LA NATURALEZA</strong></p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>&nbsp;</strong></p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo 1º.</strong> <strong>Definición.</strong> El Centro de Documentación de la Escuela de Ciencia  Política de la Universidad  de San Carlos de Guatemala, que en el transcurso de este Normativo se le  denominará simplemente con sus siglas CEDEC, es una dependencia del Instituto  de Investigaciones Políticas y Sociales, encargada de seleccionar, adquirir,  clasificar, catalogar, actualizar  y  mantener la conformación de un fondo bibliográfico  de interés sociopolítico para los programas  de docencia,  investigación y extensión  de la Escuela  de Ciencia Política.</p>
                    <p align="center" className="fw-bold"><strong>CAPITULO II</strong><br />
                        <strong>DE LOS FINES Y  OBJETIVOS</strong></p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo 2º. Fin fundamental.</strong> El CEDEC tiene como fin fundamental servir de apoyo  académico en el proceso de enseñanza – aprendizaje, investigación  y extensión de la Escuela de Ciencia  Política, actualizando sus servicios para poder responder a las necesidades de  estudiantes, docentes e investigadores e incidir en la calidad académica de la Escuela de Ciencia  Política. </p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo 3º. Objetivos.</strong>  Son objetivos del CEDEC los  siguientes:</p>
                    <div align="justify">
                        <blockquote>
                            <p> a)<span className="fw-lighter fst-italic"> Constituir  un fondo bibliográfico, documental y hemerográfico que responda a las  necesidades de las carreras que imparte la Escuela de Ciencia Política : Relaciones  Internacionales, Sociología y Ciencia Política.</span></p>
                        </blockquote>
                    </div>
                    <div align="justify">
                        <blockquote>
                            <p>b) <span className="fw-lighter fst-italic">Recopilar y  difundir, información sobre normativas    emitidas por el Consejo Superior Universitario de la Universidad de San  Carlos, como del Consejo Directivo de la Escuela de Ciencia Política, cuyo contenido sea  de interés para los estudiantes de esta Unidad Académica.</span></p>
                            <p>c) <span className="fw-lighter fst-italic">Organizar,  desarrollar y modernizar sus servicios con tecnología para poder brindar el  servicio de consulta en línea.</span></p>
                        </blockquote>
                    </div>
                    <p align="justify">&nbsp;</p>
                    <p align="justify">&nbsp;</p>
                    <p align="center" className="fw-bold"><strong>CAPITULO III</strong><br />
                        <strong>DE LAS FUNCIONES</strong></p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo 4º. Funciones.</strong>  Son funciones del CEDEC las  siguientes:</p>
                    <div align="justify">
                        <blockquote>
                            <p>a) <span className="fw-lighter fst-italic">Seleccionar,  adquirir, catalogar, clasificar, conservar y poner a disposición de los  estudiantes, docentes e investigadores material bibliográfico, documental,  hemerográfico, audiovisual  y otros.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">b) <span className="fw-lighter fst-italic">Prestar y  difundir a los usuarios los servicios que ofrece el CEDEC como apoyo al proceso  de enseñanza – aprendizaje.</span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>c) <span className="fw-lighter fst-italic">Orientar  a los usuarios en el uso de los servicios  bibliotecarios y en las fuentes de información propias de cada carrera que se  imparten en la Escuela  de Ciencia Política.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">d) <span className="fw-lighter fst-italic">Capacitar y  actualizar en el conocimiento y la aplicación de técnicas bibliotecológicas al  personal del CEDEC.      </span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>e) <span className="fw-lighter fst-italic">Establecer  relaciones de cooperación con otras instituciones para el canje y donación de  materiales bibliográficos.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">f) <span className="fw-lighter fst-italic">Participar  en programas que promuevan el mejoramiento de los servicios bibliotecarios.</span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>g) <span className="fw-lighter fst-italic">Participar  en el Sistema Bibliotecario de la Universidad de San Carlos de Guatemala (SIBUSAC). </span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">h) <span className="fw-lighter fst-italic">Participar  en foros, seminarios, congresos, foros y   todo evento científico –                social de interés para la   Escuela de Ciencia Política.</span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>i) <span className="fw-lighter fst-italic">Planificar  anualmente los requerimientos básicos de material bibliográfico y documental,  mobiliario y equipo del CEDEC.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">&nbsp;</p>
                    </blockquote>
                    <p align="center" className="fw-bold"><strong>CAPITULO IV</strong><br />
                        <strong>DE LA ORGANIZACIÓN</strong></p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo 5º. Estructura Organizativa.</strong>  Para el cumplimiento de sus  funciones el CEDEC está organizado en:</p>
                    <div align="justify">
                        <blockquote>
                            <p>a) <span className="fw-lighter fst-italic">Procesos Técnicos: Se selecciona, adquiere,  cataloga, clasifica y se digitaliza toda la información que ingresa al CEDEC</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">b) <span className="fw-lighter fst-italic">Servicios al  Usuario: Incluye la sección de circulación y préstamo, sección de devolución  interna, sección de referencia. </span></p>
                    </blockquote>
                    <p align="center" className="fw-bold"><span className="fw-lighter fst-italic"><strong></strong></span><strong>CAPITULO V</strong><br />
                        <strong>DEL REGIMEN  FINANCIERO</strong></p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>&nbsp;</strong></p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo 6º. Asignación  presupuestaria. </strong> El CEDEC deberá contar con una asignación  presupuestal ordinaria que permita cubrir sus necesidades de funcionamiento e  inversión.</p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo 7º. Otras  Fuentes de Financiamiento: </strong>EL CEDEC  podrá disponer de fondos extraordinarios procedentes de multas por mora en la  devolución de libros y extensión de solvencias.   Además formará parte de su presupuesto autofinanciable los ingresos  provenientes del arrendamiento de sala de lectura, de equipo de cómputo y  videos; impresión de fotocopias, bibliografías, información de Internet ; y  otros servicios que en el futuro se autoricen. </p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo 8º. Revisión de  multas y tarifas.  </strong>Las multas y tarifas se actualizarán según las  revisiones emanadas de la   Biblioteca Central y autorizadas por el Consejo Superior  Universitario las cuales son efectivas para todo el Sistema Bibliotecario de la USAC.</p>
                    <p align="center" className="fw-bold"><strong>CAPITULO VI</strong><br />
                        <strong>SELECCIÓN Y EXPURGO  DE MATERIAL</strong></p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Articulo 9º. El comité de  Expurgo y Selección. </strong>Se encargará  de hacer que se cumplan las normas y criterios<em> que se </em>establezcan para  la selección y el expurgo de materiales. Está integrado por la Jefe del CEDEC, y  un auxiliar de Biblioteca II. </p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo 10. Funciones. </strong>El Comité de Selección y Expurgo, tiene las  funciones siguientes:</p>
                    <div align="justify">
                        <blockquote>
                            <p>a) <span className="fw-lighter fst-italic">Definir las  políticas para la formación y el fortalecimiento del acervo bibliográfico,  documental y hemerográfico, con base en los criterios establecidos en el  presente Normativo. </span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">b) <span className="fw-lighter fst-italic">Revisar el  material existente para preservar su buen estado.</span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>c) <span className="fw-lighter fst-italic">Evaluar el  material que ofrecen al CEDEC, libreros, editoriales, universidades,  instituciones de investigación, organismos gubernamentales y no  gubernamentales, previo a su adquisición.</span></p>
                        </blockquote>
                    </div>
                    <div align="justify"></div>
                    <blockquote>
                        <p align="justify">d) <span className="fw-lighter fst-italic">Seleccionar  el material que ofrece el mercado nacional e internacional al CEDEC, previo a  su compra, para atender las necesidades de los usuarios.
                        </span>      </p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>e) <span className="fw-lighter fst-italic">Seleccionar  los materiales a canjear con otras instituciones, así como los materiales que  se reciban o se otorguen en calidad de donación.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">f) <span className="fw-lighter fst-italic">Divulgar  toda adquisición bibliográfica de reciente ingreso al Centro de Documentación.      </span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>g) <span className="fw-lighter fst-italic">Aceptar o  rechazar el material bibliográfico que le ofrezcan en calidad de canje o donación.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">h) <span className="fw-lighter fst-italic">Eliminar el  material obsoleto, deteriorado, mutilado o que por diferentes causas no ha sido  utilizado.</span></p>
                    </blockquote>
                    <div align="justify"></div>
                    <p align="justify">&nbsp;</p>
                    <h4 align="center" className="fw-bold">CAPITULO VII</h4>
                    <h4 align="center" className="fw-bold">DE LAS COLECCIONES</h4>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo 11. Colecciones. </strong> Las colecciones del  CEDEC están integradas por material  bibliográfico formado por libros de texto y de consulta sobre Ciencias  Sociales,  Tesis de los egresados de la Escuela de Ciencia  Política, Revistas extranjeras y nacionales, Documentos afines a las tres  carreras, Material Hemerográfico y audiovisual.</p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo 12. Formulación  de Políticas. </strong> Para la formulación de políticas orientadas a  la formación y fortalecimiento del acervo bibliográfico,  el Comité de Selección y Expurgo, debe  considerar los aspectos siguientes:</p>
                    <div align="justify">
                        <blockquote>
                            <p>a) <span className="fw-lighter fst-italic">Demanda de  materiales bibliográficos, en términos cualitativos y cuantitativos.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">b) <span className="fw-lighter fst-italic">Equilibrios  en las colecciones de acuerdo a necesidades de los docentes, y principalmente  de los estudiantes de las tres carreras que se imparten en la Escuela.</span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>c) <span className="fw-lighter fst-italic">Incremento  de la población estudiantil.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">d) <span className="fw-lighter fst-italic">Programas de  estudio de las tres carreras. </span></p>
                    </blockquote>
                    <div align="justify"></div>
                    <p align="justify" className="fw-lighter fst-italic"><strong>&nbsp;</strong></p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>&nbsp;</strong></p>
                    <p align="center" className="fw-bold"><strong>CAPITULO VIII</strong><br />
                        <strong>DE LOS SERVICIOS Y  USUARIOS</strong></p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo 13. Servicios. </strong> EL CEDEC  presta a  los estudiantes de la Escuela de Ciencia  Política, docentes, investigadores y público en general, los siguientes  servicios:</p>
                    <div align="justify">
                        <blockquote>
                            <p>a) <span className="fw-lighter fst-italic">Servicio de  Referencia: Es el servicio que orienta al usuario en la búsqueda de información  precisa, ofrece servicios de consulta en base de datos, brinda apoyo a la  investigación.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">b) <span className="fw-lighter fst-italic">Colección  General: Es el servicio que proporciona material bibliográfico para consulta  interna y externa.</span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>c) <span className="fw-lighter fst-italic">Tesario: Es  el servicio que suministra al usuario las tesis de grado de los egresados de la Escuela de Ciencia  Política.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">d) <span className="fw-lighter fst-italic">Audiovisuales:  Es el servicio que brinda material en videocasetes, DVD, medios digitales y  otros.</span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>e) <span className="fw-lighter fst-italic">Servicio de  Reprografía: Es el servicio que posibilita la reproducción de material  bibliográfico, excepto de aquellos materiales que a criterio de la jefatura  quedan excluidos por razón de antigüedad, su estado de conservación o por  constituir obras valiosas o insustituibles.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">f) <span className="fw-lighter fst-italic">Sala de  Consulta y lectura: Es el área destinada para uso de los estudiantes, docentes  e investigadores y público en general     con propósito exclusivamente para la consulta de material bibliográfico  del CEDEC.</span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>g) <span className="fw-lighter fst-italic">Otros  servicios y jornadas que en el futuro se creen, de conformidad a la demanda del  servicio y disponibilidad de recursos.</span></p>
                        </blockquote>
                    </div>
                    <p align="justify">&nbsp;</p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo 14. Condiciones  de uso. </strong>Las condiciones  para el uso de la sala de consulta, mobiliario, equipo, materiales del fondo  bibliográfico e instalaciones del CEDEC son las siguientes:</p>
                    <div align="justify">
                        <blockquote>
                            <p>a) <span className="fw-lighter fst-italic">La sala de  consulta y lectura, puede ser utilizada para actividades propias del  CEDEC, y actividades extraordinarias  requeridas por las Autoridades de la   Escuela de Ciencia Política quienes deberán  solicitar por escrito el servicio, por lo  menos con cinco (5) días de anticipación, indicando la fecha y horario de uso.  La persona que solicite la Sala  de Lectura, será responsable de velar por el orden y correcto uso. Así mismo,  firmará una hoja de responsabilidad por el mobiliario y equipo prestado.</span></p>
                        </blockquote>
                    </div>
                    <p align="justify">&nbsp;</p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo 15</strong>. <strong>Usuarios de Pleno Derecho.</strong>  Son usuarios  de pleno derecho: </p>
                    <div align="justify">
                        <blockquote>
                            <p>a) <span className="fw-lighter fst-italic">Los  estudiantes regulares,  de la Escuela de Ciencia  Política </span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">b) <span className="fw-lighter fst-italic">Estudiantes  pendientes de Examen Privado y Público.</span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>c)<span className="fw-lighter fst-italic"> Docentes, investigadores y personal  administrativo de la Escuela  de Ciencia Política, quienes tienen derecho al préstamo externo en las  condiciones que establece el presente Normativo.</span></p>
                        </blockquote>
                    </div>
                    <p align="justify">&nbsp;</p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo 16</strong>. <strong>Usuarios de Derecho Limitado:</strong> son usuarios de derecho limitado:</p>
                    <div align="justify">
                        <blockquote>
                            <p>a) <span className="fw-lighter fst-italic">Los  egresados de la Escuela  de Ciencia Política.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">b) <span className="fw-lighter fst-italic">Estudiantes de  otras facultades </span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>c)<span className="fw-lighter fst-italic">  Los trabajadores administrativos de la Universidad de San  Carlos.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">d) <span className="fw-lighter fst-italic">Cualquier  persona ajena a la comunidad universitaria, quienes tienen derecho únicamente a  préstamo interno en las condiciones establecidas en este Normativo.</span></p>
                    </blockquote>
                    <div align="justify"></div>
                    <p align="justify">&nbsp;</p>
                    <h4 align="center" className="fw-bold">CAPITULO IX</h4>
                    <p align="center" className="fw-bold"><strong>DEL PRESTAMO</strong></p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo 17. Tipos de  préstamo. </strong>Se establecen los  tipos de préstamo siguientes:</p>
                    <div align="justify">
                        <blockquote>
                            <p>a) <span className="fw-lighter fst-italic">Préstamo Interno: Es el  servicio donde los usuarios hacen uso de las diferentes colecciones del  CEDEC dentro de la sala de consulta.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">b) <span className="fw-lighter fst-italic">Préstamo Externo: Es el  servicio mediante el cual los usuarios obtienen el préstamo de libros para uso  externo, procediendo de acuerdo a lo establecido en el presente Normativo. </span></p>
                    </blockquote>
                    <div align="justify"></div>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo 18. Condiciones  del préstamo. </strong>El servicio de  préstamo estará regulado de la manera siguiente:</p>
                    <div align="justify">
                        <blockquote>
                            <p>a) <span className="fw-lighter fst-italic">Préstamo  Interno: Los estudiantes inscritos  de la Escuela de Ciencia  Política y de otras facultades de la Universidad de San Carlos,  pueden hacer uso del servicio de préstamo  interno siempre que presenten carné estudiantil vigente.  Cualquier otra persona ajena a la comunidad  universitaria podrá presentar cédula de vecindad o pasaporte en el caso de  usuarios extranjeros.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">b) <span className="fw-lighter fst-italic">Préstamo  Externo: Tienen derecho a préstamo externo los estudiantes regulares de la Escuela de Ciencia  Política, inscritos y con carné universitario vigente. Los trabajadores  administrativos, docentes e investigadores de la Escuela de Ciencia  Política quienes deben acreditar plenamente dicha calidad e identificarse  con carné de trabajador universitario  vigente.</span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>c) <span className="fw-lighter fst-italic">Los  estudiantes regulares debidamente inscritos , estudiantes del Ejercicio  Profesional Supervisado, Estudiantes que realizan su examen privado y  pendientes de Tesis  de la Escuela de Ciencia  Política, podrán prestar hasta tres (3) libros y / o tesis y /o documentos  por cinco (5) días, préstamo que puede  renovarse una vez más, siempre y cuando éstos no sean solicitados por otros  estudiantes.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">d) <span className="fw-lighter fst-italic">Los  trabajadores administrativos, docentes e investigadores de la Escuela de Ciencia  Política, podrán prestar tres (3) libros hasta por ocho (8) días, préstamo que  puede renovarse por una vez más, siempre y cuando éstos no sean requeridos por  otros usuarios.</span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>e) <span className="fw-lighter fst-italic">El usuario  que tenga multa pendiente con el CEDEC, no tiene derecho a préstamo ni  renovación de préstamo.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">f) <span className="fw-lighter fst-italic">Para  solicitar el servicio, es requisito indispensable llenar la Boleta de Préstamo con los  datos que se solicitan.</span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>g) <span className="fw-lighter fst-italic">El material  de Referencia (diccionarios, enciclopedias) solo se prestan para consulta  interna y no son permitidas las fotocopias, por el deterioro que sufren.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">h) <span className="fw-lighter fst-italic">Revistas  nacionales y extranjeras,  solo se  prestan para consulta interna, salvo aquellos casos especiales autorizados por  la jefatura.</span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>i) <span className="fw-lighter fst-italic">El préstamo  interno y externo de las obras y materiales, es un servicio intransferible, en  consecuencia, la persona que firme la   Boleta de Préstamo será responsable del correcto uso y  devolución del material.  En caso de  daño, pérdida o deterioro, el responsable deberá cubrir el valor de reposición,  encuadernación o restauración, según el caso.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">j) <span className="fw-lighter fst-italic">Los  materiales  en versión digital, sólo  pueden ser prestados para consulta interna. Sin embargo, si un usuario de pleno  derecho necesita consultarlos fuera del CEDEC deberá pagar la cantidad de  veinte quetzales Q(20.00) diarios en concepto de alquiler. </span></p>
                    </blockquote>
                    <div align="justify"></div>
                    <p align="justify">&nbsp;</p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo 19. Libros Reservados. </strong>Los libros reservados son obras  de las cuales solo existe un ejemplar y por su valor, solo pueden consultarse  en sala de lectura.</p>
                    <p align="justify">&nbsp;</p>
                    <p align="justify">&nbsp;</p>
                    <p align="center" className="fw-bold"><strong>CAPITULO X</strong><br />
                        <strong>DE LAS OBLIGACIONES, PROHIBICIONES</strong><br />
                        <strong>Y SANCIONES</strong></p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>&nbsp;</strong></p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo 20. Obligaciones  de los usuarios. </strong>Son obligaciones de  los usuarios de Pleno Derecho  y Derecho  Limitado, las siguientes:</p>
                    <div align="justify">
                        <blockquote>
                            <p>a) <span className="fw-lighter fst-italic">Cumplir con las disposiciones del presente  Normativo.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">b) <span className="fw-lighter fst-italic">Cuidar y preservar el mobiliario y equipo de la  sala de consulta del CEDEC. </span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>c) <span className="fw-lighter fst-italic">Respetar los horarios establecidos para la atención  de estudiantes y público en general en sus diferentes servicios. </span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">d) <span className="fw-lighter fst-italic">Atender las disposiciones de los encargados de los  servicios del CEDEC, en casos de desalojo por medidas disciplinarias que  establezcan vencimiento de horarios, suspensión de los servicios y medidas  disciplinarias que se establezcan por faltas al presente Normativo. </span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>e) <span className="fw-lighter fst-italic">Entregar la sala, mobiliario y equipo en las mismas  condiciones en que les fueron prestados. </span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">f) <span className="fw-lighter fst-italic">Utilizar adecuadamente los fondos bibliográficos y  documentales. </span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>g) <span className="fw-lighter fst-italic">Reponer el material prestado, en condiciones  favorables para la   Universidad, cuando por su causa lo haya deteriorado o  extraviado. </span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">h) <span className="fw-lighter fst-italic">Permanecer en silencio en la sala de consulta  del  CEDEC. </span></p>
                    </blockquote>
                    <div align="justify"></div>
                    <p align="justify"><strong>Artículo 21. Prohibiciones. </strong>Los usuarios  de la sala de consulta del CEDEC tienen terminante prohibido lo siguiente:</p>
                    <div align="justify">
                        <blockquote>
                            <p>a) <span className="fw-lighter fst-italic">Sustraer ilegalmente materiales del  fondo bibliográfico.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">b) <span className="fw-lighter fst-italic">Hacer  uso de los servicios bibliotecarios, bajo los efectos de bebidas alcohólicas o  drogas ilegales. </span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>c) <span className="fw-lighter fst-italic">Faltar  el respeto al personal  y usuarios. </span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">d) <span className="fw-lighter fst-italic">Portar  armas de fuego, explosivos y materiales inflamables. </span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>e) <span className="fw-lighter fst-italic">Ingerir  bebidas y comida en la sala de consulta, si no cuentan con autorización previa. </span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">f) <span className="fw-lighter fst-italic">Consumir  y expender bebidas alcohólicas y fermentadas, cigarrillos, drogas y  estupefacientes. </span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>g) <span className="fw-lighter fst-italic">Manchar,  rayar, mutilar o causar cualquier daño o deterioro al material que se les haya  proporcionado para su consulta y uso. </span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">h) <span className="fw-lighter fst-italic">Distribuir  y utilizar material pornográfico dentro de las instalaciones y equipo. </span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>i) <span className="fw-lighter fst-italic">Participar  y promover los juegos de azar dentro de las instalaciones. </span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">j) <span className="fw-lighter fst-italic">Dañar  las instalaciones, mobiliario, equipo y materiales. </span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>k) <span className="fw-lighter fst-italic">Colocar  propaganda, carteles, afiches y otros similares en lugares no autorizados para  el efecto. </span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">l) <span className="fw-lighter fst-italic">Ingresar  a las instalaciones y ambientes con animales y mascotas. </span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>m) <span className="fw-lighter fst-italic">Hacer uso de vocabulario soez y  ofensivo. </span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">n) <span className="fw-lighter fst-italic">Protagonizar  escenas amorosas y conductas inadecuadas dentro de las instalaciones. </span></p>
                    </blockquote>
                    <div align="justify"></div>
                    <p align="justify">&nbsp;</p>
                    <p align="justify" className="fw-lighter fst-italic"> <br />
                        <strong>Artículo  22. Sanciones. </strong>El incumplimiento de las disposiciones  del presente Normativo ameritará, según la falta, la aplicación de las  sanciones siguientes:</p>
                    <div align="justify">
                        <blockquote>
                            <p>a) <span className="fw-lighter fst-italic">El usuario que pierda o deteriore el  material recibido en calidad de préstamo está obligado a reponer el mismo  título con el mismo autor.  De no  encontrarlo en plaza, el CEDEC autorizará la devolución de otro libro con el  mismo tema que se considere útil para los usuarios.  El usuario responsable de la pérdida o  deterioro, además, deberá pagar cincuenta quetzales (Q 50.00) para sufragar el  valor del proceso técnico de catalogación y clasificación del material bibliográfico.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">b) <span className="fw-lighter fst-italic">Por  demora en la entrega del material prestado, a los usuarios de pleno derecho, se  les cobrará una multa de un quetzal (Q 1.00) por cada día de retraso y por cada  material prestado.  Dicha multa deberá  ser pagada al personal del  CEDEC. </span></p>
                    </blockquote>
                    <div align="justify"></div>
                    <blockquote>
                        <p align="justify">c) <span className="fw-lighter fst-italic">El  usuario que sin autorización expresa, extraiga material otorgado en préstamo  interno en la sala de consulta, será sancionado con una multa de diez quetzales  (Q 10.00) diarios por cada material sustraído. </span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>d) <span className="fw-lighter fst-italic"> Por incurrir en las prohibiciones contenidas  en los incisos b), c), d), h) y n) del Artículo 23 del presente Normativo, el  personal del CEDEC está facultado para solicitar el desalojo del usuario o  usuarios que hayan incurrido en dichas faltas,   y se le suspenderá el servicio bibliotecario por seis (6) meses y en  casos de reincidencia, la suspensión del servicio tendrá carácter permanente. </span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">e) <span className="fw-lighter fst-italic">Los usuarios que causen, sin premeditación, daños  al mobiliario, equipo y edificio del CEDEC deberán pagar el valor de la  reparación<em>.</em> </span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>f) <span className="fw-lighter fst-italic">Los usuarios que causen, con premeditación, mala fe  o por riñas, daños a los materiales, mobiliario, equipo y edificio del CEDEC,  deberán sufragar el valor de la reparación de los daños causados y asimismo, se  le suspenderá el servicio por un semestre. </span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">g) <span className="fw-lighter fst-italic">Al usuario que se encuentre en posesión o viendo  material pornográfico dentro de las instalaciones del CEDEC o haciendo uso  inadecuado del equipo de cómputo, se le cobrará una multa de cien quetzales  (Q100.00) y se le suspenderán los servicios que presta el CEDEC durante tres  (3) meses. </span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>h) <span className="fw-lighter fst-italic">Cuando el usuario tenga en su poder una o más obras  no devueltas en tiempo, se le suspenderá el servicio bibliotecario hasta que  solvente su situación. </span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">i) <span className="fw-lighter fst-italic">Cuando el usuario tenga una multa pendiente de  pagar o daños de cualquier índole pendientes de reparar o resarcir, se le  suspenderá el servicio bibliotecario, hasta que solvente su situación </span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>j) <span className="fw-lighter fst-italic">Cuando el usuario extraiga material del fondo  bibliográfico, en forma premeditada con fines de robo, se le suspenderá el  servicio por el plazo de dos (2) años.   En caso de reincidencia la suspensión del servicio tendrá carácter  definitivo. </span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">k) <span className="fw-lighter fst-italic">Por identificarse con documentos falsos, en  cualquier trámite del CEDEC, se suspenderá el servicio durante seis (6) meses. </span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>l) <span className="fw-lighter fst-italic">Por hacer uso de los servicios bibliotecarios, bajo  los efectos de bebidas alcohólicas o fermentadas, drogas y estupefacientes, así  como portar armas de fuego, explosivos y materiales inflamables, se suspenderán  los servicios bibliotecarios por seis (6) meses. </span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">m) <span className="fw-lighter fst-italic"> Las  prohibiciones establecidas en las literales e), g), i),  l), m),   del Artículo 23 del presente Normativo, serán sancionadas con llamada de  atención verbal o escrita, según la magnitud de la falta.  De reincidirse en las mismas se suspenderá el  servicio al infractor por seis (6) meses. </span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>n) <span className="fw-lighter fst-italic">Por agredir física, verbal o en cualquier forma al  personal del CEDEC o a otros usuarios, la suspensión del servicio tiene  carácter permanente. </span></p>
                        </blockquote>
                    </div>
                    <p align="justify">&nbsp;</p>
                    <h2 align="center" className="fw-bold">CAPÍTULO XI</h2>
                    <p align="center" className="fw-bold"><strong>DISPOSICONES ESPECIALES</strong></p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo 23. Suspensión de los Servicios por Fuerza Mayor.</strong>  El CEDEC se reserva el derecho de suspender  el servicio por razones de fuerza mayor, debiendo notificar oportunamente al  usuario que corresponda.</p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo 24. Pérdida</strong> <strong>de Objetos y Materiales</strong>.   El CEDEC como dependencia universitaria y su personal no son  responsables por la pérdida de objetos y materiales olvidados por los usuarios.</p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo  25. Solvencia del CEDEC</strong>. Tiene un costo de un quetzal (Q. 1.00) y es requisito  indispensable presentarla para los siguientes trámites: </p>
                    <div align="justify">
                        <blockquote>
                            <p>a) <span className="fw-lighter fst-italic">Emisión  o reposición de carné.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">b) <span className="fw-lighter fst-italic">Asignación  de cursos.</span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>c) <span className="fw-lighter fst-italic">Exámenes  finales de semestre:</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">d)<span className="fw-lighter fst-italic"> Trámite para examen general de conocimientos  (privado).</span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>e)<span className="fw-lighter fst-italic"> Trámites para aprobación de Tesis.</span></p>
                        </blockquote>
                    </div>
                    <blockquote>
                        <p align="justify">f)<span className="fw-lighter fst-italic"> Trámite de EPS.</span></p>
                    </blockquote>
                    <div align="justify">
                        <blockquote>
                            <p>g) <span className="fw-lighter fst-italic"> Para tramitar finiquito laboral en los casos  de Personal administrativo y docente.  La División de  Administración de Personal, no dará trámite a ningún expediente por pago de  prestaciones o indemnización de los trabajadores que se retiren  definitivamente, que no presenten la solvencia del CEDEC.</span></p>
                        </blockquote>
                    </div>
                    <p align="justify">&nbsp;</p>
                    <h2 align="justify">&nbsp;</h2>
                    <h2 align="center" className="fw-bold">CAPÍTULO XII</h2>
                    <h2 align="center" className="fw-bold">DISPOSICIONES  FINALES Y TRANSITORIAS</h2>
                    <p align="justify" className="fw-lighter fst-italic"><strong>&nbsp;</strong></p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo  26. Casos no Previstos</strong>. Los casos no contemplados en el presente Normativo  serán resueltos por El Consejo Directivo de la Escuela.</p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo  27. Fundamento.</strong> El presente Normativo se fundamenta en el Normativo de Biblioteca Central de la Universidad de San  Carlos de Guatemala, el cual es aplicable para todo el Sistema Bibliotecario de  la Universidad  de San Carlos (SIBUSAC) aprobado según Acuerdo de Rectoria 13-72-2005 del 26 de  noviembre del 2005, y  en el punto  NOVENO, del Acta No. 20-2004, del 08 de septiembre del 2004 del Consejo  Superior Universitario que aprobó la propuesta para Sostenibilidad y Desarrollo  de los Servicios Bibliotecarios y Centros de Documentación</p>
                    <p align="justify" className="fw-lighter fst-italic"><strong>Artículo  28. Vigencia del Normativo. </strong>El  presente Normativo entrará en vigencia ocho (8) días después de la fecha de  aprobación del Consejo Directivo de la Escuela de Ciencia Política.</p>
                </div>
            </div>
        </section>
    )
}
